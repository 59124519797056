import { GraphicEq } from '@mui/icons-material';
import recordPage from '../media/setup/record_page_i_record.png';
import recordPageRecording from '../media/setup/record_page_recording_i_record.png';
import recordPageComplete from '../media/setup/recording_complete_i_record.png';

function RecordEncounterSection() {
    return <div className="md:pl-12 py-12 pt-8">
        <div className="lg:flex lg:flex-row pb-10 md:h-[40rem]">
            <div className="w-full lg:w-1/2 px-24">
                <img src={recordPage} className='md:h-[30rem]' alt="Screenshot which shows the default page which is displayed when the app opens."/>
            </div>
            <div className="w-full lg:w-1/2 py-12  px-10 md:pr-10">
                <div className="space-y-10 text-left text-md lg:text-lg text-stop-it-red mx-auto">
                    <div className="justify-start lg:justify-left space-x-8">
                        <p>1. To access the recording page either ask your device personal assistant to record using stop it! or open the app. If you are already using the app you can access the record page from the microphone icon on the navigation bar on the bottom of the page or the red circle on the bottom right of the page on android.</p>
                    </div>
                    <div className="justify-start lg:justify-left space-x-8">
                        <p>Open the recording page to continue</p>
                    </div>
                </div>
            </div>
        </div>
        <div className="lg:flex lg:flex-row pb-10 md:h-[40rem]">
            <div className="w-full lg:w-1/2 px-24">
                <img src={recordPageRecording} className='md:h-[30rem]' alt="Screenshot showing the page which allows you to select recordings to view"/>
            </div>
            <div className="w-full lg:w-1/2 py-12  px-10 md:pr-10">
                <div className="space-y-10 text-left text-md lg:text-lg text-stop-it-red mx-auto">
                    <div className="justify-start lg:justify-left space-x-8">
                        <p>2. Click start recording to continue. If this is your first time recording you may need to accept permissions for the app to use different device services such as the camera, microphone and location detection (if you have chosen to have the locations recorded).</p>
                    </div>
                    <div className="justify-start lg:justify-left space-x-8">
                        <p>Press 'stop recording' to continue</p>
                    </div>
                </div>
            </div>
        </div>
        <div className="lg:flex lg:flex-row pb-10 md:h-[40rem]">
            <div className="w-full lg:w-1/2 px-24">
                <img src={recordPageComplete} className='md:h-[30rem]' alt="Screenshot which allows you to playback your recording."/>
            </div>
            <div className="w-full lg:w-1/2 py-12  px-10 md:pr-10">
                <div className="space-y-10 text-left text-md lg:text-lg text-stop-it-red mx-auto">
                    <div className="justify-start lg:justify-left space-x-8">
                        <p>3. When the recording stops this page will be displayed. It will show you the name of your recording. Once you have pressed stop it may take a moment for the recording to save, especially if the recording is long.</p>
                    </div>
                    <div className="justify-start lg:justify-left space-x-8">
                        <p>Click on go to recording to open a page to review the recording</p>
                    </div>
                </div>
            </div>
        </div>
    </div>;
}

export default RecordEncounterSection;