import { GraphicEq } from '@mui/icons-material';
import viewRecordPage from '../media/setup/record_page_i_view_recording.png';
import recordingPage from '../media/setup/recording_page_i_view_recording.png';
import viewRecordingPage from '../media/setup/view_recording_i_view_recording.png';
import deleteRecordingPage from '../media/setup/confirmation_i_delete_recording.png';

function DeleteRecordingSection() {
    return <div className="px-12 md:pl-12 py-12 pt-8">
        <div className="lg:flex lg:flex-row pb-10 md:h-[40rem]">
            <div className="w-full lg:w-1/2 px-24 md:px-24">
                <img src={viewRecordPage} className='md:h-[30rem]' alt="Screenshot which shows the default page which is displayed when the app opens."/>
            </div>
            <div className="w-full lg:w-1/2 py-12 md:pr-10">
                <div className="space-y-10 text-left text-md lg:text-lg text-stop-it-red mx-auto">
                    <div className="justify-start lg:justify-left space-x-8">
                        <p>1. When you open the app after it has been set up the record page will be displayed.</p>
                    </div>
                    <div className="justify-start lg:justify-left space-x-8">
                        <p>Click on this icon ( <GraphicEq /> ) to continue to a list of recordings.</p>
                    </div>
                </div>
            </div>
        </div>
        <div className="lg:flex lg:flex-row pb-10 md:h-[40rem]">
            <div className="w-full lg:w-1/2 px-24 md:px-24">
                <img src={recordingPage} className='md:h-[30rem]' alt="Screenshot showing the page which allows you to select recordings to view"/>
            </div>
            <div className="w-full lg:w-1/2 py-12 md:pr-10">
                <div className="space-y-10 text-left text-md lg:text-lg text-stop-it-red mx-auto">
                    <div className="justify-start lg:justify-left space-x-8">
                        <p>2. This page shows all recordings stored in the app. If the app has been deleted recordings can also be found in the downloads folder on your phone.</p>
                    </div>
                    <div className="justify-start lg:justify-left space-x-8">
                        <p>Select, or make then select, a recording to continue.</p>
                    </div>
                </div>
            </div>
        </div>
        <div className="lg:flex lg:flex-row pb-10 md:h-[40rem]">
            <div className="w-full lg:w-1/2 px-24 md:px-24">
                <img src={viewRecordingPage} className='md:h-[30rem]' alt="Screenshot which allows you to playback your recording."/>
            </div>
            <div className="w-full lg:w-1/2 py-12 md:pr-10">
                <div className="space-y-10 text-left text-md lg:text-lg text-stop-it-red mx-auto">
                    <div className="justify-start lg:justify-left space-x-8">
                        <p>3. This screen allows you to review your recording and play it back to yourself. On this page press delete to start deleting the recoding. To prevent the police from deleting the recording you must confirm you want the recording to be deleted after 24 hours.</p>
                    </div>
                    <div className="justify-start lg:justify-left space-x-8">
                        <p>Look to the next page to see what the 24 hour wait to confirm will look like.</p>
                    </div>
                </div>
            </div>
        </div>
        <div className="lg:flex lg:flex-row pb-10 md:h-[40rem]">
            <div className="w-full lg:w-1/2 px-24 md:px-24">
                <img src={deleteRecordingPage} className='md:h-[30rem]' alt="A screenshot showing after the delete button has been pressed."/>
            </div>
            <div className="w-full lg:w-1/2 py-12 md:pr-10">
                <div className="space-y-10 text-left text-md lg:text-lg text-stop-it-red mx-auto">
                    <div className="justify-start lg:justify-left space-x-8">
                        <p>4. This page shows what happens when delete is pressed before the first 24 hour deletion period.</p>
                    </div>
                    <div className="justify-start lg:justify-left space-x-8">
                        <p>After the second deletion is confirmed a pop-up will be displayed on the screen to confirm that the recording has been deleted.</p>
                    </div>
                </div>
            </div>
        </div>
    </div>;
}

export default DeleteRecordingSection;