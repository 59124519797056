import './App.css';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Home from './pages/Home';
import StopAndSearch from './pages/StopAndSearch';
import StopAndSearchRights from './pages/StopAndSearchRights';
import WhatWeDo from './pages/WhatWeDo';
import Instructions from './pages/Instructions';
import PrivacyPolicy from './pages/PrivacyPolicy';
import Login from './pages/Login';

function App() {
  return (<BrowserRouter>
    <Routes>
        <Route index element={<Home />} />
        <Route path='stop-and-search' element={<StopAndSearch />} />
        <Route path='stop-and-search-rights' element={<StopAndSearchRights />} />        
        <Route path='what-we-do' element={<WhatWeDo />} />   
        <Route path='instructions' element={<Instructions />} />  
        <Route path='privacy-policy' element={<PrivacyPolicy />} />
        <Route path='login' element={<Login />} />                      
    </Routes>
  </BrowserRouter>);
}

export default App;
