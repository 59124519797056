import Logo from "./Logo";

function Footer() {
    return <div className="mx-12 md:mx-36">
        <div className="lg:flex lg:flex-row lg:space-x-12 lg:justify-between px-4">
            <div className="w-full lg:w-fit">
                <h2 className="text-stop-it-red font-semibold text-xl pt-8">Pages</h2>
                <div className="py-4 ml-4">
                    <a className="text-stop-it-red font-semibold cursor-pointer" href="/what-we-do">What we do</a><br/><br/>
                    <a className="text-stop-it-red font-semibold cursor-pointer" href="/stop-and-search">The problem with stop and search</a><br/><br/>
                    <a className="text-stop-it-red font-semibold cursor-pointer" href="/stop-and-search-rights">Your rights in a stop and search</a><br/><br/>
                    <a className="text-stop-it-red font-semibold cursor-pointer" href="/instructions">Instructions</a><br/><br/>
                </div>
            </div>
            <div className="w-full lg:w-fit space-y-4">
                    <h2 className="text-stop-it-red font-semibold text-xl pt-8">Download</h2>
                    <div className="flex flex-row space-x-4 w-fit cursor-pointer ml-4">
                        <img class='w-4 h-4 place-self-center cursor-pointer' src="./apple.svg" alt='apple app store link' />
                        <h4 className="text-stop-it-red font-semibold">App Store</h4>
                    </div>
                    <div className="flex flex-row space-x-4 w-fit cursor-pointer ml-4">
                        <img class='w-4 h-4 place-self-center cursor-pointer' src="./google-play.svg" alt='google play store link' />
                        <h4 className="text-stop-it-red font-semibold">Google Play</h4>
                    </div>
            </div>
            <div className="w-full lg:w-fit">
                <h2 className="text-stop-it-red font-semibold text-xl pt-8">Contact details</h2>
                <div className="py-4 ml-4">
                    <a className="text-stop-it-red font-semibold cursor-pointer">contact@stopit.com</a><br/><br/>
                    {/* <a className="text-stop-it-red font-semibold cursor-pointer">01252 835 653</a><br/><br/> */}
                    {/* <a className="text-stop-it-red font-semibold cursor-pointer">
                        Office 112, <br/>
                        Parsley Administrative District,<br/>
                        Thyme, <br/>
                        United Kingdom, <br/>
                        GU15 1DY    
                    </a><br/><br/> */}
                </div>
            </div>
        </div>
    </div>;
}

export default Footer;