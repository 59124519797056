
function LandingBanner() {
    return <div className="z-0 h-fit bg-stop-it-red flex flex-row px-12">
        <div className="=p-12 md:pl-12 mt-20 pb-20 pt-10">
            <h1 className="text-5xl text-white font-bold mb-2">The Problem with Stop and Search</h1>
            <h2 className="text-xl text-white font-semibold opacity-80">Why Stop It is needed</h2>
        </div>
    </div>;
}

export default LandingBanner;