import MicIcon from '@mui/icons-material/Mic';
import SendIcon from '@mui/icons-material/Send';
import GavelRoundedIcon from '@mui/icons-material/GavelRounded';

function InverseTextAndBulletPointSection() {
    return <div className="px-24 py-12 bg-stop-it-red pt-20">
        <h2 className="text-white font-semibold text-2xl pb-12">How It Works</h2>
        <div className="lg:flex lg:flex-row md:pl-12 py-12 pt-8">
            <div className="w-full lg:w-1/2 px-4">
                <p className="mx-auto lg:mx-0 text-md lg:text-lg text-white py-12 text-center lg:text-left lg:w-3/4">
                Stop It! allows you to discreetly record Your 
                encounter with the police during a stop and 
                Search. As long as the app is not closed, 
                even if you are on another app or your 
                phone screen is turned off it will still record 
                audio.<br/><br/>
                You can then send the recording to our 
                the pool of lawyers who will get back to 
                you if you have a case. 
                </p>
            </div>
            <div className="w-full lg:w-1/2 py-12 px-4">
                <div className="space-y-20 text-center text-md lg:text-lg text-white mx-auto">
                    <div className="flex flex-row justify-center lg:justify-left space-x-8">
                        <MicIcon className="scale-150"/>
                        <p>Record the encounter discretely with the app</p>
                    </div>
                    <div className="flex flex-row justify-center lg:justify-left space-x-8">
                        <SendIcon className="scale-125" />
                        <p>Record the encounter discretely with the app</p>
                    </div>
                </div>
            </div>
        </div>
    </div>;
}

export default InverseTextAndBulletPointSection;