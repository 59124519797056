import React, { useEffect, useLayoutEffect, useState } from "react";
import "../output.css";
import Logo from "./Logo";
import NavbarDownloadSection from "./NavbarDownloadSection";
import MenuIcon from '@mui/icons-material/Menu';

function Navbar() {
    const [count, setCount] = useState(0);

    const [isOpen, setOpen] = useState(false);
    const [screenWidth, setScreenWidth] = useState(window.innerWidth);

    useEffect(() => {
        if(count == 0) {
            setCount(count + 1);
            window.addEventListener('resize', () => {
                setScreenWidth(window.innerWidth);
            });
        }
    });

    function toggleMobileMenuOpen() {
        setOpen(!isOpen);
    }

    function isNotLarge() {
        return screenWidth < 1024;
    }

    if(isOpen) {
        return (<div className="z-[999] absolute h-screen max-w-screen left-0 right-0 top-0 b-0 bg-white">
            <div className="absolute l-0 r-0 pt-6 w-screen cursor-pointer h-4 scale-50">
                <Logo center={true} />
            </div>
            <div className="flex flex-row space-between">
                <div className="grow pt-4 ml-1/8 pl-4" onClick={toggleMobileMenuOpen}>
                    <MenuIcon className="cursor-pointer text-stop-it-red" />
                </div>
                <div className="grow invisible">
                </div>
            </div>
            <div className="px-12">
                <h2 className="text-stop-it-red font-semibold text-xl pt-8">Pages</h2>
                <div className="py-4 ml-4">
                    <a className="text-stop-it-red font-semibold cursor-pointer" href="/what-we-do">What we do</a><br/><br/>
                    <a className="text-stop-it-red font-semibold cursor-pointer" href="/stop-and-search">The problem with stop and search</a><br/><br/>
                    <a className="text-stop-it-red font-semibold cursor-pointer" href="/stop-and-search-rights">Your rights in a stop and search</a><br/><br/>
                    <a className="text-stop-it-red font-semibold cursor-pointer" href="/instructions">Instructions</a><br/><br/>
                </div>
                <h2 className="text-stop-it-red font-semibold text-xl pt-8">Download</h2>
                <div className="py-4 ml-4 space-y-8">
                    <div className="flex flex-row space-x-4 w-fit cursor-pointer">
                        <img class='w-4 h-4 place-self-center cursor-pointer' src="./apple.svg" alt='apple app store link' />
                        <h4 className="text-stop-it-red font-semibold">App Store</h4>
                    </div>
                    <div className="flex flex-row space-x-4 w-fit cursor-pointer">
                        <img class='w-4 h-4 place-self-center cursor-pointer' src="./google-play.svg" alt='google play store link' />
                        <h4 className="text-stop-it-red font-semibold">Google Play</h4>
                    </div>
                </div>
            </div>
        </div>);
    }

    return (<div className="z-[999] absolute bg-white left-0 right-0 top-0 p-4 px-10 flex flex-row shadow-md max-w-screen">
            {
                isNotLarge() && <div className="grow ml-1/8 lg:shrink lg:ml-0 lg:w-0 lg:invisible">
                    <div onClick={toggleMobileMenuOpen}>
                        <MenuIcon className="cursor-pointer text-stop-it-red" />
                    </div>
                </div>
            }
            <div className="absolute inset-0 lg:pt-0 pt-6 mx-auto lg:relative lg:shrink w-fit cursor-pointer w-4 h-4 scale-50">
                <Logo />
            </div>
            <div className="invisible lg:visible lg:grow w-0 h-0 lg:h-fit lg:w-fit flex flex-row justify-start  text-center text-stop-it-red">
                <a href="/what-we-do" className="lg:mr-8">
                    What we do
                </a>
                <a href="/stop-and-search" className="lg:mr-8">
                   Stop and search
                </a>
                <a href="/stop-and-search-rights" className="lg:mr-8">
                   Stop and search rights
                </a>
                <a href="/instructions">
                    Instructions
                </a>
            </div>
            <div className="invisible lg:visible grow lg:shrink w-36">
                {/* <NavbarDownloadSection /> */}
            </div>
        </div>);
}

export default Navbar;