import ChevronRightIcon from '@mui/icons-material/ChevronRight';

function LinkBanner() {
    return (<div className="z-0 px-24 py-8 bg-stop-it-red">
        <h2 className="text-white font-semibold text-2xl pb-12">Useful Information</h2>
        <div className="lg:flex lg:flex-row text-white text-xl pb-8 pt-2">
            <div className="w-full lg:w-1/2 text-center my-2 px-4">
                <a href='/stop-and-search-rights'>Your rights in a stop and search <ChevronRightIcon /></a>
            </div>
            <div className="w-full lg:w-1/2 text-center my-2 px-4">
                <a href='/stop-and-search'>The problem with a stop and search <ChevronRightIcon /></a>
            </div>
        </div>
    </div>);
}

export default LinkBanner;