import Footer from "../components/Footer";
import Navbar from "../components/Navbar";
import TextAndBulletPointSection from "../components/TextAndBulletPointSection";
import LandingBanner from "../components/LandingBanner";
import List from "../components/List";

function StopAndSearchRights() {
    return <div>
        <Navbar />
        <LandingBanner />
        <List />
        <Footer />
    </div>;
}

export default StopAndSearchRights;