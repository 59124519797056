import Logo from "../components/Logo";

function Login() {
    return <div className="w-screen h-fit">
        <div className="w-1/2 h-fit text-center mt-[12.5%] mx-auto bg-slate-100 shadow rounded space-y-4 px-4 pb-20 py-10">
            <div className="w-40 scale-75 mx-auto">
                <Logo />
            </div>
            <h1 className="py-2 text-2xl font-semibold text-stop-it-red">Login</h1>
            <a href="https://stop-it-lawyer-panel.web.app" className="text-md max-w-[75%] mx-auto text-white bg-stop-it-red hover:text-stop-it-red border-2 py-0/5 hover:bg-white cursor-pointer border-stop-it-red px-4 py-2 rounded-lg ml-auto justify-end ml-auto block">I'm a lawyer</a>
            <a href="https://stop-it-client-panel.web.app" className="text-md max-w-[75%] mx-auto text-white bg-stop-it-red hover:text-stop-it-red border-2 py-0/5 hover:bg-white cursor-pointer border-stop-it-red px-4 py-2 rounded-lg ml-auto justify-end ml-auto block">I'm a user</a>
        </div>
    </div>;
}

export default Login;