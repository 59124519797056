import AppSection from "../components/AppSection";
import Footer from "../components/Footer";
import LinkBanner from "../components/LinkBanner";
import Navbar from "../components/Navbar";
import TextAndBulletPointSection from "../components/TextAndBulletPointSection";
import Landing from "../components/Landing";

function Home() {
    return <div>
        <Navbar />
        <Landing />
        <TextAndBulletPointSection />
        <LinkBanner />
        <AppSection />
        <Footer />
    </div>;
}

export default Home;