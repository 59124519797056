import { GraphicEq } from '@mui/icons-material';
import recordPage from '../media/setup/record_page_i_record.png';
import sendPage from '../media/setup/send_page_i_send.png';
import enterDatePage from '../media/setup/enter_date_i_send.png';
import uploadingPage from '../media/setup/uploading_i_send.png';
import uploadedPage from '../media/setup/uploaded_i_send.png';

function SendRecordingSection() {
    return <div className="md:pl-12 py-12 pt-8">
        <div className="lg:flex lg:flex-row pb-10 md:h-[40rem]">
            <div className="w-full lg:w-1/2 px-24">
                <img src={sendPage} className='md:h-[30rem]' alt="Review recording page"/>
            </div>
            <div className="w-full lg:w-1/2 py-12  px-10 md:pr-10">
                <div className="space-y-10 text-left text-md lg:text-lg text-stop-it-red mx-auto">
                    <div className="justify-start lg:justify-left space-x-8">
                        <p>1. Navigate to the view page (an example of this can be found in the review recording section of this page) or select the recording on the send recording page.</p>
                    </div>
                    <div className="justify-start lg:justify-left space-x-8">
                        <p>Press the 'send to lawyer' button or proceed to the next step</p>
                    </div>
                </div>
            </div>
        </div>
        <div className="lg:flex lg:flex-row pb-10 md:h-[40rem]">
            <div className="w-full lg:w-1/2 px-24">
                <img src={enterDatePage} className='md:h-[30rem]' alt="Enter date page."/>
            </div>
            <div className="w-full lg:w-1/2 py-12  px-10 md: px-10 md:pr-10">
                <div className="space-y-10 text-left text-md lg:text-lg text-stop-it-red mx-auto">
                    <div className="justify-start lg:justify-left space-x-8">
                        <p>2. This will take you to an enter date page. This is a statement which should be completed to be sent to your lawyer. If the recording is long you will need to create an acccount on our free online portal and sign in. If this is the case a prompt will show up, if not an email page will open and you can send the recording in an email to your lawyer.</p>
                    </div>
                    <div className="justify-start lg:justify-left space-x-8">
                        <p>Click on 'send to lawyer' to continue</p>
                    </div>
                </div>
            </div>
        </div>
        <div className="lg:flex lg:flex-row pb-10 md:h-[40rem]">
            <div className="w-full lg:w-1/2 px-24">
                <img src={uploadingPage} className='md:h-[30rem]' alt="Screenshot of upload popup."/>
            </div>
            <div className="w-full lg:w-1/2 py-12  px-10 md:pr-10">
                <div className="space-y-10 text-left text-md lg:text-lg text-stop-it-red mx-auto">
                    <div className="justify-start lg:justify-left space-x-8">
                        <p>3. Once you have signed in a popup (shown on the image) will be shown which will show you the progress of the upload.</p>
                    </div>
                    <div className="justify-start lg:justify-left space-x-8">
                        <p>Wait for a second popup to show confirming the file has been uploaded</p>
                    </div>
                </div>
            </div>
        </div>
        <div className="lg:flex lg:flex-row pb-10 md:h-[40rem]">
            <div className="w-full lg:w-1/2 px-24">
                <img src={uploadedPage} className='md:h-[30rem]' alt="Screenshot of upload complete popup."/>
            </div>
            <div className="w-full lg:w-1/2 py-12  px-10 md:pr-10">
                <div className="space-y-10 text-left text-md lg:text-lg text-stop-it-red mx-auto">
                    <div className="justify-start lg:justify-left space-x-8">
                        <p>4. Once the recording has uploaded a page like this will be displayed. The lawyer will be sent an email and told to login to view your recording.</p>
                    </div>
                    <div className="justify-start lg:justify-left space-x-8">
                        <p>Click on 'ok' on the popup to return to the home screen</p>
                    </div>
                </div>
            </div>
        </div>
    </div>;
}

export default SendRecordingSection;