import start from '../media/setup/start_ios.jpg';
import tos from '../media/setup/tos_ios.jpg';
import data from '../media/setup/data_ios.jpg';
import lawyer from '../media/setup/lawyer_ios.jpg';
import done from '../media/setup/done_ios.jpg';
import alert from '../media/setup/shortcut_alert_ios.jpg';
import shortcut from '../media/setup/shortcut_ios.jpg';

function SetUpTheAppSection() {
    return <div className="md:pl-12 py-12 pt-8">
        <div className="lg:flex lg:flex-row pb-2 md:h-[40rem]">
            <div className="w-full lg:w-1/2 px-24">
                <img src={start} className='md:h-[30rem]' alt="Screenshot which includes an icon of a hand and a button to get started with setting up the app." />
            </div>
            <div className="w-full lg:w-1/2 py-12 px-10 md:pr-10">
                <div className="space-y-10 text-left text-md lg:text-lg text-stop-it-red mx-auto">
                    <div className="justify-start lg:justify-left space-x-8">
                        <p>1. On opening the app for the first time, you'll be greeted with the option to set up the app.</p>
                    </div>
                    <div className="justify-start lg:justify-left space-x-8">
                        <p>Click "Get Started" here to continue.</p>
                    </div>
                </div>
            </div>
        </div>
        <div className="lg:flex lg:flex-row pb-2 md:h-[40rem]">
            <div className="w-full lg:w-1/2 px-24">
                <img src={tos} className='md:h-[30rem]' alt="Screenshot which asks if you agree to the terms and conditions of the Stop It! service. This includes a title and an icon of a document." />
            </div>
            <div className="w-full lg:w-1/2 px-10 md:pr-10">
                <div className="space-y-10 text-left text-md lg:text-lg text-stop-it-red mx-auto">
                    <div className="justify-start lg:justify-left space-x-8">
                        <p>2. The next stage asks you to agree to the terms and conditions of Stop It!</p>
                    </div>
                    <div className="justify-start lg:justify-left space-x-8">
                        <p>You must agree before clicking "Next" to continue.</p>
                    </div>
                </div>
            </div>
        </div>
        <div className="lg:flex lg:flex-row pb-2 md:h-[40rem]">
            <div className="w-full lg:w-1/2 px-24">
                <img src={data} className='md:h-[30rem]' alt="Screenshot which asks if you agree to send information about when stops are recorded, and the location of where stops occur." />
            </div>
            <div className="w-full lg:w-1/2 py-12  px-10 md:pr-10">
                <div className="space-y-10 text-left text-md lg:text-lg text-stop-it-red mx-auto">
                    <div className="justify-start lg:justify-left space-x-8">
                        <p>3. The next screen asks if you agree to share data with Stop It! Agreeing to these options helps the Stop It! team to create a more accurate snapshot of your interactions.</p>
                    </div>
                    <div className="justify-start lg:justify-left space-x-8">
                        <p>You must agree to these before clicking "Next" to continue.</p>
                    </div>
                </div>
            </div>
        </div>
        <div className="lg:flex lg:flex-row pb-2 md:h-[40rem]">
            <div className="w-full lg:w-1/2 px-24">
                <img src={lawyer} className='md:h-[30rem]' alt="Screenshot which asks you to either send recording data to the Stop It! lawyers or to a lawyer of your own choosing using an email textfield. This screen has a gavel icon and a title." />
            </div>
            <div className="w-full lg:w-1/2 py-12  px-10 md:pr-10">
                <div className="space-y-10 text-left text-md lg:text-lg text-stop-it-red mx-auto">
                    <div className="justify-start lg:justify-left space-x-8">
                        <p>4. The next stage allows you to choose between sending recordings to a lawyer of your choice with the red textfield, or to the Stop It! legal team using the button.</p>
                    </div>
                    <div className="justify-start lg:justify-left space-x-8">
                        <p>You must choose one before clicking "Next" to continue.</p>
                    </div>
                </div>
            </div>
        </div>
        <div className="lg:flex lg:flex-row pb-2 md:h-[40rem]">
            <div className="w-full lg:w-1/2 px-24">
                <img src={done} className='md:h-[30rem]' alt="Screenshot which asks you to complete the set up phase and continue on to the home screen. This screen includes a green tick icon." />
            </div>
            <div className="w-full lg:w-1/2 py-12  px-10 md:pr-10">
                <div className="space-y-10 text-left text-md lg:text-lg text-stop-it-red mx-auto">
                    <div className="justify-start lg:justify-left space-x-8">
                        <p>5. You've now set up Stop It!</p>
                    </div>
                    <div className="justify-start lg:justify-left space-x-8">
                        <p><b>Continue to step 6 if you use an iPhone</b>.</p>
                    </div>
                    <div className="justify-start lg:justify-left space-x-8">
                        <p>Otherwise, click "Go to Home" to finish the set up.</p>
                    </div>
                </div>
            </div>
        </div>
        <div className="lg:flex lg:flex-row pb-2 md:h-[40rem]">
            <div className="w-full lg:w-1/2 px-24">
                <img src={alert} className='md:h-[30rem]' alt="Screenshot which asks you to complete the set up phase and continue on to the home screen. This screen includes a green tick icon." />
            </div>
            <div className="w-full lg:w-1/2 py-12  px-10 md:pr-10">
                <div className="space-y-10 text-left text-md lg:text-lg text-stop-it-red mx-auto">
                    <div className="justify-start lg:justify-left space-x-8">
                        <p>6. Recordings can be started with Stop It! by either tapping the "Start Recording" button or by using voice commands. Voice commands are handeled using Siri on iPhones, clicking "Yes" adds this feature to Siri.</p>
                    </div>
                    <div className="justify-start lg:justify-left space-x-8">
                        <p>If you choose "No" here and wish to enable voice commands later, click the settings icon from the Record page, then select "Add recording shortcut". This requires that the "Shortcuts" app is installed from the App Store <a href="https://apps.apple.com/us/app/shortcuts/id1462947752">here</a>.</p>
                    </div>
                </div>
            </div>
        </div>
        <div className="lg:flex lg:flex-row pb-2 md:h-[40rem]">
            <div className="w-full lg:w-1/2 px-24">
                <img src={shortcut} className='md:h-[30rem]' alt="Screenshot which asks you to complete the set up phase and continue on to the home screen. This screen includes a green tick icon." />
            </div>
            <div className="w-full lg:w-1/2 py-12  px-10 md:pr-10">
                <div className="space-y-10 text-left text-md lg:text-lg text-stop-it-red mx-auto">
                    <div className="justify-start lg:justify-left space-x-8">
                        <p>7. The shortcuts app will now ask you to add a Shortcut which is recognised by the Stop It! app.</p>
                    </div>
                    <div className="justify-start lg:justify-left space-x-8">
                        <p><b>Do not change the phrase. Changing the phrase will cause Stop It! to not recognise the voice command</b>.</p>
                    </div>
                    <div className="justify-start lg:justify-left space-x-8">
                        <p>If you do accidentally change the phrase, delete it from the Shortcuts App before adding it back using step 6 above.</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
}

export default SetUpTheAppSection;