import Footer from "../components/Footer";
import LandingBanner from "../components/LandingBanner";
import Navbar from "../components/Navbar";
import InstructionLinksSection from "../components/InstructionLinksSection";
import InverseSetUpTheAppHeader from "../components/InverseSetUpTheAppHeader";
import SetUpTheAppSection from "../components/SetUpTheAppSection";
import InverseReviewRecordingsHeader from "../components/InverseReviewRecordingsHeader";
import ReviewRecordingSection from "../components/ReviewRecordingsSection";
import InverseDeleteRecordingsHeader from "../components/InverseDeleteRecordingsHeader";
import DeleteRecordingSection from "../components/DeleteRecordingSection";
import RecordEncounterHeader from "../components/RecordEncounterHeader";
import RecordEncounterSection from "../components/RecordEncounterSection";
import SendRecordingsHeader from "../components/SendRecordingHeader";
import SendRecordingSection from "../components/SendRecordingSection";
// import ReviewRecordingsSection from "../components/ReviewRecordingsSection";

function Instructions() {
    return <div>
        <Navbar />
        <LandingBanner />
        <InstructionLinksSection />

        <InverseSetUpTheAppHeader />
        <SetUpTheAppSection />

        <InverseReviewRecordingsHeader />
        <ReviewRecordingSection />

        <InverseDeleteRecordingsHeader />
        <DeleteRecordingSection />

        <RecordEncounterHeader />
        <RecordEncounterSection />

        <SendRecordingsHeader />
        <SendRecordingSection />

        <Footer />
    </div>;
}

export default Instructions;