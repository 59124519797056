import Footer from "../components/Footer";
import Navbar from "../components/Navbar";
import TextAndBulletPointSection from "../components/TextAndBulletPointSection";
import LandingBanner from "../components/LandingBanner";

function StopAndSearch() {
    return <div>
        <Navbar />
        <LandingBanner />
        <TextAndBulletPointSection />
        <Footer />
    </div>;
}

export default StopAndSearch;