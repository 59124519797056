import MicIcon from '@mui/icons-material/Mic';
import SendIcon from '@mui/icons-material/Send';

function TextAndBulletPointSection() {
    return <div className="px-24 py-12 bg-white pt-20" id='how-it-works'>
        <h2 className="text-stop-it-red font-semibold text-2xl pb-12">How It Works</h2>
        <div className="lg:flex lg:flex-row md:pl-12 py-12 pt-8">
            <div className="w-full lg:w-1/2 px-4">
                <p className="mx-auto lg:mx-0 text-md lg:text-lg text-stop-it-red py-12 text-center lg:text-left lg:w-3/4">
                The Stop It!  app allows you to record your encounter with the police during a stop and search. As long as the app is not closed, even if you are on another app or your phone screen is turned off it will still record audio. 
                <br/><br/>
                You can then send the recording to our pool of Lawyers for assessment of the lawfulness of the police actions
                </p>
            </div>
            <div className="w-full lg:w-1/2 py-12 px-4">
                <div className="space-y-20 text-left text-md lg:text-lg text-stop-it-red mx-auto">
                    <div className="flex flex-row justify-start lg:justify-left space-x-8">
                        <MicIcon className="scale-150"/>
                        <p>Speak or press button to activate and record</p>
                    </div>
                    <div className="flex flex-row justify-start lg:justify-left space-x-8">
                        <SendIcon className="scale-125" />
                        <p>Use the app to send your encounter to the team of lawyers</p>
                    </div>
                </div>
            </div>
        </div>
    </div>;
}

export default TextAndBulletPointSection;