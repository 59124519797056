import Navbar from "./../components/Navbar";
import Footer from "./../components/Footer";

function PrivacyPolicy() {

    return <div>
        <Navbar />
        <div className="pt-20 px-12 py-4 bg-stop-it-red">
            <h1 className="pl-12 font-semibold text-3xl text-white">Privacy Policy</h1>
        </div>
        <p>... (all other content goes here)</p>
        <h2 className="pl-12 font-semibold text-3xl text-white">How the app uses user data</h2>
        <Footer />
    </div>;
}

export default PrivacyPolicy;