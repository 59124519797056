import ChevronRightIcon from '@mui/icons-material/ChevronRight';

function AppSection() {
    return <div className="px-24 py-12 bg-white pt-20">
        <h2 className="text-stop-it-red font-semibold text-2xl pb-12">The Stop It! App</h2>
        <div className="lg:flex lg:flex-row md:pl-12 py-12 pt-8">
            <div className="w-full lg:w-1/2 px-4">
                <img className='py-8' src='./Mask Group 2.png' />
                <div className='space-y-4'>
                    {/* <p className='text-stop-it-red text-lg'>Download the app</p>
                    <img className='cursor-pointed' src='./google-rounded.svg' alt='Google Play store link' />
                    <img className='cursor-pointed' src='./apple-rounded.svg' alt='Apple app store link' /> */}
                </div>
            </div>
            <div className="w-full lg:w-1/2 py-12 px-4">
                <div className="mt-20 space-y-32 text-left text-md lg:text-lg text-stop-it-red mx-auto flex-none">
                    <a href='./#how-it-works' className='cursor-pointer hover:scale-125 hover:ml-20 block'>The app <ChevronRightIcon /></a>
                    <a href='./instructions' className='cursor-pointer hover:scale-125 hover:ml-20 block'>Instructions <ChevronRightIcon /></a>
                </div>
            </div>
        </div>
    </div>;
}

export default AppSection;