import { AppSettingsAlt, GraphicEq, DeleteOutline, Mic, GavelRounded } from '@mui/icons-material';


function InstructionsSection() {
    return <div>
        <div className="px-24 bg-white pt-12">
            <h2 className="text-stop-it-red font-semibold text-2xl">Instructions</h2>
            <div className="lg:flex lg:flex-row md:pl-12 py-12 pt-8">
                <div className="w-full lg:w-1/2 py-12 px-4">
                    <div className="space-y-20 text-center text-md lg:text-lg text-stop-it-red mx-auto">
                        <a href="#setuptheapp"><div className="flex flex-row justify-left lg:justify-left space-x-8">
                            <AppSettingsAlt className="scale-150"/>
                            <p>Set up the app</p>
                        </div></a>
                        <a href='#reviewrecordings' className="flex flex-row justify-left lg:justify-left space-x-8">
                            <GraphicEq className="scale-150" />
                            <p>Review recordings</p>
                        </a>
                        <a href='#deleterecordings' className="flex flex-row justify-left lg:justify-left space-x-8">
                            <DeleteOutline className="scale-150" />
                            <p>Delete recording</p>
                        </a>
                    </div>
                </div>
                <div className="w-full lg:w-1/2 py-12 px-4">
                    <div className="space-y-20 text-center text-md lg:text-lg text-stop-it-red mx-auto">
                        <a href='#recordencounter' className="flex flex-row justify-left lg:justify-left space-x-8">
                            <Mic className="scale-150"/>
                            <p>Record an encounter</p>
                        </a>
                        <a href='#sendrecording' className="flex flex-row justify-left lg:justify-left space-x-8">
                            <GavelRounded className="scale-125" />
                            <p>Send the recording to a lawyer</p>
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </div>
}

export default InstructionsSection;