import { useState } from 'react';
import icon from '../logo.svg';

function Logo(props) {
    let classes = 'flex w-60';

    if(props.center && props.center == true) {
        classes += ' mx-auto pt-1'
    }

    return <a className={classes} href='/' >
        <img class="w-fit px-2 h-16 my-auto -translate-y-5" src='../logo.png' alt='logo' />
        <h1 className='place-self-center text-3xl mb-12 font-bold text-stop-it-red'>STOP IT!</h1>
    </a>;
}

export default Logo;