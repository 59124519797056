import Footer from "../components/Footer";
import Navbar from "../components/Navbar";
import TextAndBulletPointSection from "../components/TextAndBulletPointSection";
import LandingBanner from "../components/LandingBanner";
import QuoteLanding from "../components/QuoteLanding";
import InverseTextAndBulletPointSection from "../components/InverseTextAndBulletPointSection";
import List from "../components/List";
import WhatWeDoList from "../components/WhatWeDoList";

function WhatWeDo() {
    return <div>
        <Navbar />
        <QuoteLanding />
        <WhatWeDoList />
        <InverseTextAndBulletPointSection />
        <Footer />
    </div>;
}

export default WhatWeDo;