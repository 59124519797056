
function Landing() {
    return <div className="z-0 h-fit lg:h-screen bg-stop-it-red md:flex md:flex-row">
        <div className="lg:w-3/4 p-12 md:pl-28 pt-24 lg:pt-20">
            <h1 className="text-7xl text-white font-bold mb-8 mt-20">STOP IT!</h1>
            <h2 className="text-3xl text-white font-semibold opacity-80">Get justice for unlawful stop and searches</h2>
            <h2 className="text-2xl text-white font-semibold opacity-80">Record stop and search encounters and send them to lawyers</h2>
        </div>
        <div className="mx-auto lg:w-1/4 w-full scale-75">
            <img className='py-8 lg:ml-20 mx-auto z-0' src='./Mask Group 2.png' />
        </div>
    </div>;
}

export default Landing;