import MicIcon from '@mui/icons-material/Mic';
import SendIcon from '@mui/icons-material/Send';
import GavelRoundedIcon from '@mui/icons-material/GavelRounded';

function WhatWeDoList() {
    return <div className="px-24 py-12 bg-white pt-20">
        <h2 className="text-stop-it-red font-semibold text-2xl pb-12">Why we do it</h2>
        <div className="lg:flex lg:flex-row md:pl-12 py-12 pt-8">
            <div className="w-full lg:w-1/2 py-12 px-4">
                <div className="space-y-20 text-center text-md lg:text-lg text-stop-it-red mx-auto">
                    <div className="flex flex-row justify-center lg:justify-left space-x-8">
                        <MicIcon className="scale-150"/>
                        <p>Record the encounter discretely with the app</p>
                    </div>
                    <div className="flex flex-row justify-center lg:justify-left space-x-8">
                        <SendIcon className="scale-125" />
                        <p>Record the encounter discretely with the app</p>
                    </div>
                    <div className="flex flex-row justify-center lg:justify-left space-x-8">
                        <GavelRoundedIcon className="scale-150" />
                        <p>Record the encounter discretely with the app</p>
                    </div>
                </div>
            </div>
            <div className="w-full lg:w-1/2 py-12 px-4">
                <div className="space-y-20 text-center text-md lg:text-lg text-stop-it-red mx-auto">
                    <div className="flex flex-row justify-center lg:justify-left space-x-8">
                        <MicIcon className="scale-150"/>
                        <p>Record the encounter discretely with the app</p>
                    </div>
                    <div className="flex flex-row justify-center lg:justify-left space-x-8">
                        <SendIcon className="scale-125" />
                        <p>Record the encounter discretely with the app</p>
                    </div>
                    <div className="flex flex-row justify-center lg:justify-left space-x-8">
                        <GavelRoundedIcon className="scale-150" />
                        <p>Record the encounter discretely with the app</p>
                    </div>
                </div>
            </div>
        </div>
    </div>;
}

export default WhatWeDoList;